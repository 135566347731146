import * as types from "./actionTypes";
import axios from "axios";
export const getCategories = () => async (dispatch) => {
 try {
  dispatch({ type: types.GET_CATEGORY_REQUEST });
  const response = await axios.get("https://app-api.carexpert.org.in/api/categories/all");
  dispatch({ type: types.GET_CATEGORY_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_CATEGORY_FAILURE, payload: error });
 }
};

export const addCategory = (formData) => async (dispatch) => {
 try {
  dispatch({ type: types.CREATE_CATEGORY_REQUEST });

  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/categories/create",
   formData, // Send form data including file
   {
    headers: {
     "Content-Type": "multipart/form-data", // Set the content type for form data
    },
   }
  );

  dispatch({
   type: types.CREATE_CATEGORY_SUCCESS,
   payload: response.data,
  });

  // Fetch categories after successful creation
  dispatch(getCategories());
 } catch (error) {
  console.error("Error adding category:", error);
  dispatch({ type: types.CREATE_CATEGORY_FAILURE, payload: error });
 }
};
export const updateCategory = (formData) => async (dispatch) => {
 try {
  dispatch({ type: types.UPDATE_CATEGORY_REQUEST });

  const response = await axios.patch(
   `https://app-api.carexpert.org.in/api/categories/update/${formData.get("_id")}`,
   formData, // Send form data including file
   {
    headers: {
     "Content-Type": "multipart/form-data", // Set the content type for form data
    },
   }
  );

  dispatch({
   type: types.UPDATE_CATEGORY_SUCCESS,
   payload: response.data,
  });
  dispatch(getCategories());

  // Fetch categories after successful update
 } catch (error) {
  console.error("Error updating category:", error);
  dispatch({ type: types.UPDATE_CATEGORY_FAILURE, payload: error });
 }
};
export const deleteCategory = (payload) => async (dispatch) => {
 try {
  dispatch({ type: types.DELETE_CATEGORY_REQUEST });
  const response = await axios.delete(
   `https://app-api.carexpert.org.in/api/categories/rm/${payload}`,
   payload
  );
  dispatch({
   type: types.DELETE_CATEGORY_SUCCESS,
   payload: response.data.data,
  });
  dispatch(getCategories());
 } catch (error) {
  dispatch({ type: types.DELETE_CATEGORY_FAILURE, payload: error });
 }
};
export const getSubCategories = () => async (dispatch) => {
 try {
  dispatch({ type: types.GET_SUBCATEGORY_REQUEST });
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/subcategories/all"
  );
  dispatch({ type: types.GET_SUBCATEGORY_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_SUBCATEGORY_FAILURE, payload: error });
 }
};
export const getSingleSubCategories = (id) => async (dispatch) => {
 try {
  dispatch({ type: types.GET_SUBCATEGORY_REQUEST });
  const response = await axios.get(
   `https://app-api.carexpert.org.in/api/subcategories/${id}`
  );
  dispatch({ type: types.GET_SUBCATEGORY_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_SUBCATEGORY_FAILURE, payload: error });
 }
};
export const addSubCategory = (formData) => async (dispatch) => {
 try {
  dispatch({ type: types.CREATE_SUBCATEGORY_REQUEST });

  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/subcategories/create",
   formData,
   {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   }
  );

  dispatch({
   type: types.CREATE_SUBCATEGORY_SUCCESS,
   payload: response.data,
  });

  // Fetch subcategories after successful creation
  dispatch(getSubCategories());
 } catch (error) {
  console.error("Error adding subcategory:", error);
  dispatch({ type: types.CREATE_SUBCATEGORY_FAILURE, payload: error });
 }
};

export const updateSubCategory = (formData) => async (dispatch) => {
 try {
  dispatch({ type: types.UPDATE_SUBCATEGORY_REQUEST });

  const response = await axios.patch(
   `https://app-api.carexpert.org.in/api/subCategories/update/${formData.get("_id")}`,
   formData,
   {
    headers: {
     "Content-Type": "multipart/form-data",
    },
   }
  );

  dispatch({
   type: types.UPDATE_SUBCATEGORY_SUCCESS,
   payload: response.data,
  });

  // Fetch subcategories after successful update
  dispatch(getSubCategories());
 } catch (error) {
  console.error("Error updating subcategory:", error);
  dispatch({ type: types.UPDATE_SUBCATEGORY_FAILURE, payload: error });
 }
};

export const deleteSubCategory = (payload) => async (dispatch) => {
 console.log("payload:", payload);
 try {
  dispatch({ type: types.DELETE_SUBCATEGORY_REQUEST });
  const response = await axios.delete(
   `https://app-api.carexpert.org.in/api/subcategories/rm/${payload}`
  );
  dispatch({
   type: types.DELETE_SUBCATEGORY_SUCCESS,
   payload: response.data.data,
  });
  dispatch(getSubCategories());
 } catch (error) {
  dispatch({ type: types.DELETE_SUBCATEGORY_FAILURE, payload: error });
 }
};
export const fetchSubcategories = (categoryId) => async (dispatch) => {
 try {
  dispatch({ type: types.GET_SUB_CATEGORY_BASED_ON_CATEGORY_REQUEST });
  const response = await axios.get(
   `https://app-api.carexpert.org.in/api/subCategories/${categoryId}`
  );
  dispatch({
   type: types.GET_SUB_CATEGORY_BASED_ON_CATEGORY_SUCCESS,
   payload: response.data,
  });
 } catch (error) {
  dispatch({ type: types.UPDATE_SUBCATEGORY_FAILURE, payload: error });
 }
};
export const getProducts = () => async (dispatch) => {
 try {
  dispatch({ type: types.GET_PRODUCT_REQUEST });
  const response = await axios.get("https://app-api.carexpert.org.in/api/products");
  dispatch({ type: types.GET_PRODUCT_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_PRODUCT_FAILURE, payload: error });
 }
};
export const addProduct = (payload) => async (dispatch) => {
 try {
  dispatch({ type: types.CREATE_PRODUCT_REQUEST });

  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/products",
   payload
  );

  dispatch({
   type: types.CREATE_PRODUCT_SUCCESS,
   payload: response.data,
  });

  // Fetch categories after successful creation
  dispatch(getProducts());
 } catch (error) {
  console.error("Error adding category:", error);
  dispatch({ type: types.CREATE_PRODUCT_FAILURE, payload: error });
 }
};
export const updateProduct = (payload) => async (dispatch) => {
 try {
  dispatch({ type: types.UPDATE_PRODUCT_REQUEST });

  const response = await axios.patch(
   `https://app-api.carexpert.org.in/api/products/${payload._id}`,
   payload
  );
  dispatch({
   type: types.UPDATE_PRODUCT_SUCCESS,
   payload: response.data,
  });

  // Fetch categories after successful creation
  dispatch(getProducts());
 } catch (error) {
  console.error("Error adding category:", error);
  dispatch({ type: types.UPDATE_PRODUCT_FAILURE, payload: error });
 }
};
export const deleteProduct = (payload) => async (dispatch) => {
 try {
  dispatch({ type: types.DELETE_PRODUCT_REQUEST });

  const response = await axios.delete(
   `https://app-api.carexpert.org.in/api/products/${payload}`
  );
  dispatch({
   type: types.DELETE_PRODUCT_SUCCESS,
   payload: response.data,
  });

  // Fetch categories after successful creation
  dispatch(getProducts());
 } catch (error) {
  console.error("Error adding category:", error);
  dispatch({ type: types.DELETE_PRODUCT_FAILURE, payload: error });
 }
};
export const getProductById = (id) => async (dispatch) => {
 try {
  dispatch({ type: types.GET_PRODUCT_BY_ID_REQUEST });
  const response = await axios.get(
   `https://app-api.carexpert.org.in/api/products/products/${id}`
  );
  dispatch({ type: types.GET_PRODUCT_BY_ID_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_PRODUCT_BY_ID_FAILURE, payload: error.message });
 }
};
export const getProductsPriceByCar = () => async (dispatch) => {
 try {
  dispatch({ type: types.GET_PRICE_BY_CAR_REQUEST });
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/price/by/car/all"
  );
  dispatch({ type: types.GET_PRICE_BY_CAR_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_PRICE_BY_CAR_FAILURE, payload: error });
 }
};
export const getAllCarPrices = () => async (dispatch) => {
 try {
  dispatch({ type: types.GET_ALL_PRICES_REQUEST });

  const response = await axios.get("https://app-api.carexpert.org.in/api/price/all");
  // console.log("response GET_ALL_PRICES_BY CAR:", response.data);
  dispatch({
   type: types.GET_ALL_PRICES_SUCCESS,
   payload: response.data,
  });
 } catch (error) {
  console.error("Error fetching all car prices:", error);
  dispatch({ type: types.GET_ALL_PRICES_FAILURE, payload: error });
 }
};
export const addPriceBasedOnCar = (payload) => async (dispatch) => {
 try {
  dispatch({ type: types.ADD_PRICE_BY_CAR_REQUEST });

  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/price/by/car/create",
   payload
  );

  dispatch({
   type: types.ADD_PRICE_BY_CAR_SUCCESS,
   payload: response.data,
  });

  dispatch(getAllCarPrices());
 } catch (error) {
  console.error("Error adding category:", error);
  dispatch({ type: types.ADD_PRICE_BY_CAR_FAILURE, payload: error });
 }
};

export const updatePriceByCar = (id, payload) => async (dispatch) => {
 try {
  dispatch({ type: types.UPDATE_PRICE_REQUEST });

  const response = await axios.put(
   `https://app-api.carexpert.org.in/api/price/by/car/${id}`,
   payload
  );

  dispatch({
   type: types.UPDATE_PRICE_SUCCESS,
   payload: response.data,
  });

  dispatch(getAllCarPrices());
 } catch (error) {
  console.error("Error updating car price:", error);
  dispatch({ type: types.UPDATE_PRICE_FAILURE, payload: error });
 }
};
export const deletePriceByCar = (id) => async (dispatch) => {
 try {
  dispatch({ type: types.DELETE_PRICE_REQUEST });

  await axios.delete(`https://app-api.carexpert.org.in/api/price/${id}`);

  dispatch({
   type: types.DELETE_PRICE_SUCCESS,
   payload: id,
  });

  dispatch(getAllCarPrices());
 } catch (error) {
  console.error("Error deleting car price:", error);
  dispatch({ type: types.DELETE_PRICE_FAILURE, payload: error });
 }
};

export const fetchCarByBrand = (brandId) => async (dispatch) => {
 try {
  dispatch({ type: types.GET_CAR_BY_BRAND_REQUEST });
  const response = await axios.get(
   `https://app-api.carexpert.org.in/api/cars/brand/${brandId}`
  );
  dispatch({
   type: types.GET_CAR_BY_BRAND_SUCCESS,
   payload: response.data,
  });
 } catch (error) {
  dispatch({ type: types.GET_CAR_BY_BRAND_FAILURE, payload: error });
 }
};

export const fetchCarsByBrandAndTitle =
 (brandId, carTitle) => async (dispatch) => {
  try {
   dispatch({ type: types.GET_CARS_BY_BRAND_AND_TITLE_REQUEST });
   const response = await axios.get(
    `https://app-api.carexpert.org.in/api/cars/brand/${brandId}/model/${carTitle}`
   );
   dispatch({
    type: types.GET_CARS_BY_BRAND_AND_TITLE_SUCCESS,
    payload: response.data,
   });
  } catch (error) {
   dispatch({
    type: types.GET_CARS_BY_BRAND_AND_TITLE_FAILURE,
    payload: error,
   });
  }
 };
// spareparts
export const fetchSpareParts = () => async (dispatch) => {
 dispatch({ type: types.GET_SPARE_PARTS_REQUEST });
 try {
  const response = await axios.get("https://app-api.carexpert.org.in/api/spareparts");
  dispatch({ type: types.GET_SPARE_PARTS_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_SPARE_PARTS_FAILURE, payload: error.message });
 }
};

export const fetchSparePartById = (id) => async (dispatch) => {
 dispatch({ type: types.GET_SPARE_PART_REQUEST });
 try {
  const response = await axios.get(
   `https://app-api.carexpert.org.in/api/spareparts/${id}`
  );
  dispatch({ type: types.GET_SPARE_PART_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.GET_SPARE_PART_FAILURE, payload: error.message });
 }
};

export const addSparePart = (sparePart) => async (dispatch) => {
 dispatch({ type: types.ADD_SPARE_PART_REQUEST });
 try {
  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/spareparts",
   sparePart,
   {
    headers: {
     "Content-Type": "application/json",
    },
   }
  );
  dispatch({ type: types.ADD_SPARE_PART_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.ADD_SPARE_PART_FAILURE, payload: error.message });
 }
};

export const updateSparePart = (id, sparePart) => async (dispatch) => {
 dispatch({ type: types.UPDATE_SPARE_PART_REQUEST });
 try {
  const response = await axios.put(
   `https://app-api.carexpert.org.in/api/spareparts/${id}`,
   sparePart,
   {
    headers: {
     "Content-Type": "application/json",
    },
   }
  );
  dispatch({ type: types.UPDATE_SPARE_PART_SUCCESS, payload: response.data });
 } catch (error) {
  dispatch({ type: types.UPDATE_SPARE_PART_FAILURE, payload: error.message });
 }
};

export const deleteSparePart = (id) => async (dispatch) => {
 dispatch({ type: types.DELETE_SPARE_PART_REQUEST });
 try {
  await axios.delete(`https://app-api.carexpert.org.in/api/spareparts/${id}`);
  dispatch({ type: types.DELETE_SPARE_PART_SUCCESS, payload: id });
 } catch (error) {
  dispatch({ type: types.DELETE_SPARE_PART_FAILURE, payload: error.message });
 }
};
export const createSparePartPrice = (formData) => async (dispatch) => {
 dispatch({ type: types.CREATE_SPARE_PART_PRICE_REQUEST });
 try {
  const response = await axios.post(
   "https://app-api.carexpert.org.in/api/spareparts/sparepart/price",
   formData
  );
  dispatch({
   type: types.CREATE_SPARE_PART_PRICE_SUCCESS,
   payload: response.data.data, // Assuming the data returned is in `response.data.data`
  });
 } catch (error) {
  dispatch({
   type: types.CREATE_SPARE_PART_PRICE_FAILURE,
   payload: error.response?.data?.error || "Failed to create spare part prices",
  });
 }
};
export const getSparePartPrices = () => async (dispatch) => {
 dispatch({ type: types.GET_SPARE_PART_PRICES_REQUEST });
 try {
  const response = await axios.get(
   //    `https://app-api.carexpert.org.in/api/spareparts/sparepart/${sparePartId}/prices`
   `https://app-api.carexpert.org.in/api/spareparts/spareparts/all`
  );
  dispatch({
   type: types.GET_SPARE_PART_PRICES_SUCCESS,
   payload: response.data.data,
  });
 } catch (error) {
  dispatch({
   type: types.GET_SPARE_PART_PRICES_FAILURE,
   payload: error.response?.data?.error || "Failed to fetch spare part prices",
  });
 }
};
export const getSparePartPricesAll = () => async (dispatch) => {
 dispatch({ type: types.GET_SPARE_PART_PRICES_REQUEST });
 try {
  const response = await axios.get(`https://app-api.carexpert.org.in/api/spareparts`);
  dispatch({
   type: types.GET_SPARE_PART_PRICES_SUCCESS,
   payload: response.data.data,
  });
 } catch (error) {
  dispatch({
   type: types.GET_SPARE_PART_PRICES_FAILURE,
   payload: error.response?.data?.error || "Failed to fetch spare part prices",
  });
 }
};
export const updateSparePartPrice = (priceId, formData) => async (dispatch) => {
 dispatch({ type: types.UPDATE_SPARE_PART_PRICE_REQUEST });
 try {
  const response = await axios.put(
   `https://app-api.carexpert.org.in/api/spareparts/sparepart/price/${priceId}`,
   formData
  );
  dispatch({
   type: types.UPDATE_SPARE_PART_PRICE_SUCCESS,
   payload: response.data.data,
  });
 } catch (error) {
  dispatch({
   type: types.UPDATE_SPARE_PART_PRICE_FAILURE,
   payload: error.response?.data?.error || "Failed to update spare part price",
  });
 }
};
export const deleteSparePartPrice = (priceId) => async (dispatch) => {
 dispatch({ type: types.DELETE_SPARE_PART_PRICE_REQUEST });
 try {
  await axios.delete(
   `https://app-api.carexpert.org.in/api/spareparts/sparepart/price/${priceId}`
  );
  dispatch({
   type: types.DELETE_SPARE_PART_PRICE_SUCCESS,
   payload: priceId,
  });
 } catch (error) {
  dispatch({
   type: types.DELETE_SPARE_PART_PRICE_FAILURE,
   payload: error.response?.data?.error || "Failed to delete spare part price",
  });
 }
};
