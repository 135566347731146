import { Box } from "@mui/material";
import React from "react";
import BookingFeeCard from "./BookingFeeCard";
import CODVisibilityCard from "./CODVisibilityCard";

const SettingsPayments = () => {
 return (
  <Box>
   <BookingFeeCard />
   <CODVisibilityCard />
  </Box>
 );
};

export default SettingsPayments;
