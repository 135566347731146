import React from "react";
import Mainroutes from "./pages/Mainroutes";
import { Box } from "@mui/material";
 

const App = () => {
 return (
  <Box>
   <Mainroutes />
 
  </Box>
 );
};

export default App;
