import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import axios from 'axios';

const API_URL = 'https://app-api.carexpert.org.in/api'; 

 
const getPolicy = async () => {
  try {
    const response = await axios.get(`${API_URL}/policies`);
    return response.data[0];  
  } catch (error) {
    console.error('Error fetching policy:', error);
    throw error;
  }
};

 
const savePolicy = async (policy) => {
  try {
    const response = await axios.post(`${API_URL}/policies`, policy);
    return response.data;
  } catch (error) {
    console.error('Error saving policy:', error);
    throw error;
  }
};

 
const updatePolicy = async (id, policy) => {
  try {
    const response = await axios.put(`${API_URL}/policies/${id}`, policy);
    return response.data;
  } catch (error) {
    console.error('Error updating policy:', error);
    throw error;
  }
};

const RefundAndCancelation = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [policyId, setPolicyId] = useState(null);  

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const policy = await getPolicy();
        if (policy) {
          setTitle(policy.title);
          setDescription(policy.description);
          setPolicyId(policy._id);
        }
      } catch (error) {
        console.error('Failed to fetch policy:', error);
      }
    };

    fetchPolicy();
  }, []);

  const handleSave = async () => {
    try {
      const policyData = { title, description };

      if (policyId) {
        
        await updatePolicy(policyId, policyData);
        console.log('Policy updated successfully');
      } else {
        
        const savedPolicy = await savePolicy(policyData);
        setPolicyId(savedPolicy._id);  
        console.log('Policy saved successfully');
      }
    } catch (error) {
      console.error('Failed to save/update policy:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Refund and Cancellation Policy
        </Typography>

        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          margin="normal"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter policy title"
        />

        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter policy description"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          sx={{ mt: 2 }}
          fullWidth
        >
          {policyId ? 'Update' : 'Save'}
        </Button>
      </Box>
    </Container>
  );
};

export default RefundAndCancelation;
