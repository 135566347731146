import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import ResponsiveDrawer from "../components/ResponsiveDrawer";
import ReqAuth from "../components/users/PrivetRoute";
import Homepage from "./Homepage";
import About from "./About";
import Contact from "./Contact";
import Users from "./Users";
import Cars from "./Cars";
import Brand from "./Brand";
import Vendor from "./Vendor";
import VendorProfile from "./Vendorprofile";
import AdminProfile from "../components/users/AdminProfile";
import Service from "../components/Service";
import Products from "../components/Products/Products";
import Category from "../components/Products/Category";
import SubCategory from "../components/Products/SubCategory";
import PricesByCar from "../components/Products/PricesByCar";
import BookingSlots from "../components/cart/BookingSlots";
import OfferBanners from "../components/offers/OfferBanners";
import VideoTestimonials from "../components/testimonial/VideoTestimonials";
import AdminCities from "../components/city-service-provider/AdminCities";
import FaqComponent from "../components/Faq/FaqComponent";
import CustomerReviews from "../components/review/CustomerReviews";
import PartenerReviews from "../components/review/PartenerReviews";
import TermsConditions from "../components/about/TermsConditions";
import PrivacyPolicy from "../components/about/PrivacyPolicy";
import AboutUs from "../components/about/AboutUs";
import Wallet from "../components/refer-coupon-wallet/Wallet";
import CouponCode from "../components/refer-coupon-wallet/CouponCode";
import ReferAndEarn from "../components/refer-coupon-wallet/ReferAndEarn";
import AdminSpareParts from "../components/Products/AdminSpareParts";
import OrderManagement from "../components/order-management/OrderManagement";
import SomePageComponent from "../components/Products/create-price-spareparts/SomePageComponent";
import SparePartPriceForm from "../components/Products/create-price-spareparts/SparePartPriceForm";
import ToDoList from "../ToDoList";
import SettingsPayments from "../components/payment/SettingsPayments";
import ContactUs from "../components/about/ContactUs";
import RefundAndCancelation from "../components/about/RefundAndCancelation";

const Dashboard = () => {
 const { userDetails } = useSelector((store) => store.auth);
 const { role } = userDetails;
 //  console.log("userDetails:", userDetails);

 return (
  <ResponsiveDrawer isVendor={role === "vendor"}>
   <Routes>
    {role !== "vendor" && (
     <>
      <Route
       path="/"
       element={
        <ReqAuth>
         <Homepage />
        </ReqAuth>
       }
      />
      <Route
       path="about"
       element={
        <ReqAuth>
         <About />
        </ReqAuth>
       }
      />
      <Route
       path="contact"
       element={
        <ReqAuth>
         <Contact />
        </ReqAuth>
       }
      />
      <Route
       path="users"
       element={
        <ReqAuth>
         <Users />
        </ReqAuth>
       }
      />
      <Route
       path="/cars"
       element={
        <ReqAuth>
         <Cars />
        </ReqAuth>
       }
      />
      <Route
       path="/brands"
       element={
        <ReqAuth>
         <Brand />
        </ReqAuth>
       }
      />
      <Route
       path="/product/categories"
       element={
        <ReqAuth>
         <Category />
        </ReqAuth>
       }
      />
      <Route
       path="/product/subcategories"
       element={
        <ReqAuth>
         <SubCategory />
        </ReqAuth>
       }
      />
      <Route
       path="/product"
       element={
        <ReqAuth>
         <Products />
        </ReqAuth>
       }
      />
      <Route
       path="/product/prices"
       element={
        <ReqAuth>
         <PricesByCar />
        </ReqAuth>
       }
      />
      <Route
       path="/product/spare-parts"
       element={
        <ReqAuth>
         <AdminSpareParts />
        </ReqAuth>
       }
      />
      <Route
       path="/product/spare-parts/price"
       element={
        <ReqAuth>
         <SparePartPriceForm />
        </ReqAuth>
       }
      />
      <Route
       path="/offers"
       element={
        <ReqAuth>
         <OfferBanners />
        </ReqAuth>
       }
      />
      <Route
       path="/review"
       element={
        <ReqAuth>
         <CustomerReviews />
        </ReqAuth>
       }
      />
      <Route
       path="/partner-review"
       element={
        <ReqAuth>
         <PartenerReviews />
        </ReqAuth>
       }
      />
      <Route
       path="/about-us"
       element={
        <ReqAuth>
         <AboutUs />
        </ReqAuth>
       }
      />
      <Route
       path="/privacy-policy"
       element={
        <ReqAuth>
         <PrivacyPolicy />
        </ReqAuth>
       }
      />
      <Route
       path="/terms-conditions"
       element={
        <ReqAuth>
         <TermsConditions />
        </ReqAuth>
       }
      />
      <Route
       path="/wallets"
       element={
        <ReqAuth>
         <Wallet />
        </ReqAuth>
       }
      />
      <Route
       path="/coupon-codes"
       element={
        <ReqAuth>
         <CouponCode />
        </ReqAuth>
       }
      />
      <Route
       path="/refer-and-earn"
       element={
        <ReqAuth>
         <ReferAndEarn />
        </ReqAuth>
       }
      />
      <Route
       path="/testimonials"
       element={
        <ReqAuth>
         <PricesByCar />
        </ReqAuth>
       }
      />
      <Route
       path="/testimonials-vid"
       element={
        <ReqAuth>
         <VideoTestimonials />
        </ReqAuth>
       }
      />
      <Route
       path="/faq"
       element={
        <ReqAuth>
         <FaqComponent />
        </ReqAuth>
       }
      />
      <Route
       path="/city-services"
       element={
        <ReqAuth>
         <AdminCities />
        </ReqAuth>
       }
      />
      <Route
       path="/slots"
       element={
        <ReqAuth>
         <BookingSlots />
        </ReqAuth>
       }
      />
      <Route
       path="profile"
       element={
        <ReqAuth>
         <AdminProfile />
        </ReqAuth>
       }
      />
      <Route
       path="/order-management"
       element={
        <ReqAuth>
         <OrderManagement />
        </ReqAuth>
       }
      />
     </>
    )}
    <Route
     path="vendor-profile"
     element={
      <ReqAuth>
       <VendorProfile />
      </ReqAuth>
     }
    />
    <Route
     path="/todo"
     element={
      <ReqAuth>
       <ToDoList />
      </ReqAuth>
     }
    />
    <Route
     path="/payment-setting"
     element={
      <ReqAuth>
       <SettingsPayments />
      </ReqAuth>
     }
    />
    <Route
     path="/refund-cancelation"
     element={
      <ReqAuth>
       <RefundAndCancelation />
      </ReqAuth>
     }
    />
    <Route
     path="/contact-us"
     element={
      <ReqAuth>
       <ContactUs />
      </ReqAuth>
     }
    />
   </Routes>
  </ResponsiveDrawer>
 );
};

export default Dashboard;

// import React from "react";
// import { Route, Routes } from "react-router-dom";
// import ResponsiveDrawer from "../components/ResponsiveDrawer";
// import Homepage from "./Homepage";
// import About from "./About";
// import Contact from "./Contact";
// import Users from "./Users";
// import Cars from "./Cars";
// import Brand from "./Brand";
// import { useSelector } from "react-redux";
// import Vendor from "./Vendor";
// import VendorProfile from "./Vendorprofile";
// import ReqAuth from "../components/users/PrivetRoute";
// import AdminProfile from "../components/users/AdminProfile";
// import Service from "../components/Service";
// import Products from "../components/Products/Products";
// import Category from "../components/Products/Category";
// import SubCategory from "../components/Products/SubCategory";
// import PricesByCar from "../components/Products/PricesByCar";
// import BookingSlots from "../components/cart/BookingSlots";
// import OfferBanners from "../components/offers/OfferBanners";
// import VideoTestimonials from "../components/testimonial/VideoTestimonials";
// import AdminCities from "../components/city-service-provider/AdminCities";
// import FaqComponent from "../components/Faq/FaqComponent";
// import CustomerReviews from "../components/review/CustomerReviews";
// import PartenerReviews from "../components/review/PartenerReviews";
// import TermsConditions from "../components/about/TermsConditions";
// import PrivacyPolicy from "../components/about/PrivacyPolicy";
// import AboutUs from "../components/about/AboutUs";
// import Wallet from "../components/refer-coupon-wallet/Wallet";
// import CouponCode from "../components/refer-coupon-wallet/CouponCode";
// import ReferAndEarn from "../components/refer-coupon-wallet/ReferAndEarn";

// const Dashboard = () => {
//   const { userDetails } = useSelector((store) => store.auth);
//   const { role } = userDetails;

//   return (
//     <ResponsiveDrawer isVendor={role === "vendor"}>
//       <Routes>
//         {role !== "vendor" && (
//           <>
//             <Route
//               path="homepage"
//               element={
//                 <ReqAuth>
//                   <Homepage />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="about"
//               element={
//                 <ReqAuth>
//                   <About />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="contact"
//               element={
//                 <ReqAuth>
//                   <Contact />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="users"
//               element={
//                 <ReqAuth>
//                   <Users />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/cars"
//               element={
//                 <ReqAuth>
//                   <Cars />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/brands"
//               element={
//                 <ReqAuth>
//                   <Brand />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/product/categories"
//               element={
//                 <ReqAuth>
//                   <Category />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/product/subcategories"
//               element={
//                 <ReqAuth>
//                   <SubCategory />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/product"
//               element={
//                 <ReqAuth>
//                   <Products />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/product/prices"
//               element={
//                 <ReqAuth>
//                   <PricesByCar />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/offers"
//               element={
//                 <ReqAuth>
//                   <OfferBanners />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/review"
//               element={
//                 <ReqAuth>
//                   <CustomerReviews />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/partner-review"
//               element={
//                 <ReqAuth>
//                   <PartenerReviews />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/about-us"
//               element={
//                 <ReqAuth>
//                   <AboutUs />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/privacy-policy"
//               element={
//                 <ReqAuth>
//                   <PrivacyPolicy />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/terms-conditions"
//               element={
//                 <ReqAuth>
//                   <TermsConditions />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/wallets"
//               element={
//                 <ReqAuth>
//                   <Wallet />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/coupon-codes"
//               element={
//                 <ReqAuth>
//                   <CouponCode />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/refer-and-earn"
//               element={
//                 <ReqAuth>
//                   <ReferAndEarn />
//                 </ReqAuth>
//               }
//             />

//             <Route
//               path="/testimonials"
//               element={
//                 <ReqAuth>
//                   <PricesByCar />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/testimonials-vid"
//               element={
//                 <ReqAuth>
//                   <VideoTestimonials />
//                 </ReqAuth>
//               }
//             />

//             <Route
//               path="/faq"
//               element={
//                 <ReqAuth>
//                   <FaqComponent />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/city-services"
//               element={
//                 <ReqAuth>
//                   <AdminCities />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/slots"
//               element={
//                 <ReqAuth>
//                   <BookingSlots />
//                 </ReqAuth>
//               }
//             />
//             <Route
//               path="/product/prices"
//               element={
//                 <ReqAuth>
//                   <PricesByCar />
//                 </ReqAuth>
//               }
//             />

//             <Route
//               path="profile"
//               element={
//                 <ReqAuth>
//                   <AdminProfile />
//                 </ReqAuth>
//               }
//             />
//           </>
//         )}

//         <Route
//           path="vendor-profile"
//           element={
//             <ReqAuth>
//               <VendorProfile />
//             </ReqAuth>
//           }
//         />
//       </Routes>
//     </ResponsiveDrawer>
//   );
// };

// export default Dashboard;
